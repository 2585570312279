var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-title",
        { staticStyle: { "padding-left": "15px" } },
        [
          _vm._v(_vm._s(_vm.showResponses ? "Response Status" : "Members")),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "my-auto",
              attrs: { "aria-label": "cross", icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeDialog.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("v-card-subtitle", { staticClass: "py-0 font-weight-bold" }, [
                _vm._v(
                  _vm._s(_vm.ticket.center_ticket_id) +
                    "-" +
                    _vm._s(_vm.ticket.revision)
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center", attrs: { cols: "3" } },
            [
              _c(
                "v-chip",
                {
                  staticClass: "px-3",
                  attrs: {
                    "x-small": "",
                    "text-color": "white",
                    color: _vm.priorityIconColor(_vm.ticket.priority),
                  },
                },
                [_vm._v(_vm._s(_vm.ticket.priority) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.ticket.receipt.response_due
        ? _c("v-card-subtitle", { staticClass: "pt-0 mb-3 pl-3" }, [
            _vm._v(
              _vm._s(_vm.$t("responseDue")) +
                ": " +
                _vm._s(
                  _vm.ticket.receipt.response_due
                    ? _vm.formatTicketDateTime(_vm.ticket.receipt.response_due)
                    : null
                )
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }