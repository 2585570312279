<template>
  <div>
    <v-card-title style="padding-left: 15px"
      >{{ showResponses ? "Response Status" : "Members" }}<v-spacer />
      <v-btn
        class="my-auto"
        aria-label="cross"
        icon
        @click.native="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-row>
      <v-col cols="9">
        <v-card-subtitle class="py-0 font-weight-bold"
          >{{ ticket.center_ticket_id }}-{{ ticket.revision }}</v-card-subtitle
        >
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <v-chip
          class="px-3"
          x-small
          text-color="white"
          :color="priorityIconColor(ticket.priority)"
          >{{ ticket.priority }}
        </v-chip>
      </v-col>
    </v-row>
    <v-card-subtitle class="pt-0 mb-3 pl-3" v-if="ticket.receipt.response_due"
      >{{ $t("responseDue") }}:
      {{
        ticket.receipt.response_due
          ? formatTicketDateTime(ticket.receipt.response_due)
          : null
      }}</v-card-subtitle
    >
  </div>
</template>
<script>
import { formatTicketDateTime } from "@/store/utils/utils.js";
export default {
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
    showResponses: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    priorityIconColor(priority) {
      switch (priority) {
        case "RUSH":
          return "#fdd835";
        case "EMER":
          return "red";
        case "NONC":
          return "light-blue";
        default:
          return "grey";
      }
    },
    formatTicketDateTime(dateString) {
      return formatTicketDateTime(dateString);
    },
    closeDialog() {
      this.$emit("hideTicketResponses");
    },
  },
};
</script>

<style></style>
